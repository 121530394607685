import React from "react";

const ComponentDidnotLoad = () => {
  return (
    <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg">
      <p className="text-4xl font-bold mb-4">
        Uh-oh, it looks like our little website gremlins have gone on a rampage!
      </p>
      <p className="text-lg mb-4">
        Don't worry, though, they're probably just taking their afternoon nap.
        Give it a minute and I'm sure everything will be up and running again.
      </p>
      <p className="text-lg mb-4">
        In the meantime, why don't you go grab a snack and stretch those legs?
        The tech gods will surely have mercy on us eventually.
      </p>
    </div>
  );
};

export default ComponentDidnotLoad;
