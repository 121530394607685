import React from "react";

const Projects = () => {
  return (
    <div>
      <div className="font-bold text-2xl">
        <h3>Projects untill now...</h3>
        <h5>🧵</h5>
      </div>
    </div>
  );
};

export default Projects;
