import React from "react";

const Footer = () => {
  return (
    <div>
      <p className="text-white text-center py-4">
        ©{new Date().getFullYear()} Arth Prajapati. All rights reserved.
      </p>
    </div>
  );
};

export default Footer;
